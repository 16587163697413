import React, { useState, useEffect } from 'react'
import GoogleMapsLink from './GoogleMapsLink.js'
import AppleMapsLink from './AppleMapsLink.js'
import TreeRenderer from '../tree/TreeRenderer'
import Modal from 'react-modal';
import '../adminStyles.css'
import moment from 'moment'

const GeneralTabContent = ({ bookingData, handleFieldChange, setChangeMade }) => {
  const [selectedTime, setSelectedTime] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [user, setUser] = useState('');
  const [medicalWaiverSigned, setMedicalWaiverSigned] = useState(false);
  const [medicalChartSent, setMedicalChartSent] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nurseArrived, setNurseArrived] = useState(false);
  const [successResponseAddPersonToBooking, setSuccessResponseAddPersonToBooking] = useState(false)
  // const [nurseArrived, setNurseArrived] = useState(bookingData.extraStuff && bookingData.extraStuff.nurseArrivalTime);
  const [currentTree, setCurrentTree] = useState(null);
  const [day, setDay] = useState(1);
  const [month, setMonth] = useState(1);
  const [year, setYear] = useState(2024);
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const years = Array.from({ length: 120 }, (_, i) => new Date().getFullYear() - i);

  const [patientData, setPatientData] = useState([{
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  }]);
  const formatTime = (dateTime) => {
    if (!dateTime) return '';
    const date = new Date(dateTime);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const [estimatedArrivalTime, setEstimatedArrivalTime] = useState(formatTime(bookingData.startTime));

  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setUser(data.user)
          // fetchWorkSchedule(currentDate); // Fetch work schedules for the initial date on page load
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  useEffect(() => {
    // Initialize nurseArrived based on bookingData only when the component mounts
    checkWaiver();
    if (bookingData.extraStuff && bookingData.extraStuff.nurseArrivalTime) {
      setNurseArrived(true);
    }
    // Initialize medicalChartSent based on bookingData
    if (bookingData.extraStuff && bookingData.extraStuff.medicalChartSent) {
      setMedicalChartSent(bookingData.extraStuff.medicalChartSent);
    }
  }, [bookingData.extraStuff])

  useEffect(() => {
    if (bookingData && bookingData.patient) {
      const birthday = bookingData.patient.birthday || new Date()
      const date = moment(birthday).local();
      setDay(date.date() + 1); // getDate() is replaced with date()
      setMonth(date.month() + 1); // getMonth() is 0-indexed
      setYear(date.year());
    }
  }, []);

  const checkWaiver = () => {
    fetch('/api/admin/checkWaiver', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: bookingData.uuid,
      })
    })
      .then(response => response.json())
      .then(data => {
        // console.log('Waiver data:', data);
        if (data.authenticated) {
          setMedicalWaiverSigned(data.waiverSigned);
        } else {
          window.location.href = '/login';
        }
      })
      .catch(error => {
        console.error('Error checking waiver:', error);
      });
  }
  const openForm = (treeName) => {
    setCurrentTree(treeName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // setCurrentTree(null);
  };

  const updatePatientInfo = (event) => {
    const button = event.target;

    fetch('/api/admin/updatePatient', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: bookingData.patient.uuid,
        firstName: bookingData.patient.firstName,
        middleName: bookingData.patient.middleName,
        lastName: bookingData.patient.lastName,
        sex: bookingData.patient.sex,
        birthday: bookingData.patient.birthday,
        address1: bookingData.address1,
        address2: bookingData.address2,
        city: bookingData.city,
        state: bookingData.state,
        zipcode: bookingData.zipcode,
        phone: bookingData.phone
      })
    })
      .then(response => response.json())
      .then(data => {
        setChangeMade(false)
        button.classList.add('green');
        setTimeout(() => button.classList.remove('green'), 3000);
        // const sortedData = data.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
        // setWorkSchedules(sortedData);
        // likely needs to update state such the list of services
      })
  }

  const lateText = (event) => {
    const button = event.target;

    fetch('/api/admin/textLate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: bookingData.patient.uuid,
        firstName: bookingData.patient.firstName,
        middleName: bookingData.patient.middleName,
        lastName: bookingData.patient.lastName,
        sex: bookingData.patient.sex,
        birthday: bookingData.patient.birthday,
        address1: bookingData.address1,
        address2: bookingData.address2,
        city: bookingData.city,
        state: bookingData.state,
        zipcode: bookingData.zipcode,
        phone: bookingData.phone,
        startTime: bookingData.startTime,
        estimatedArrivalTime,
      })
    })
      // .then(response => response.json())
      .then(data => {
        button.classList.add('green');
        setTimeout(() => button.classList.remove('green'), 3000);
      })
  }

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedPatientData = patientData.map((data, i) =>
      i === index ? { ...data, [name]: value } : data
    );
    setChangeMade(true)
    setPatientData(updatedPatientData)
  };

  const handleEstimatedArrivalTimeChange = (event) => {
    setEstimatedArrivalTime(event.target.value);
    setChangeMade(true)
  };

  //  add green button on submission success
  useEffect(() => {
    if (successResponseAddPersonToBooking && !showPopup) {
      const button = document.getElementById('submitNewPersonToBookingButton');
      button.classList.add('green');
      setTimeout(() => {
        button.classList.remove('green');
        setSuccessResponseAddPersonToBooking(false); // Reset the flag
      }, 3000);
    }
  }, [successResponseAddPersonToBooking, showPopup]);

  const submitPeopleToBooking = (e) => {
    e.preventDefault();
    // Submit data to /api/admin/addToBooking
    fetch('/api/admin/addPeopleToBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        bookingData,
        people: patientData
      })
    })
      .then(response => response.json())
      .then(data => {
      // Handle response

        setShowPopup(false);
        setChangeMade(false)
        setSuccessResponseAddPersonToBooking(true);
        setPatientData({ firstName: '', lastName: '', email: '', phone: '' });
      })
      .catch(error => {
        console.error('Error adding person to booking:', error);
      });
  };

  const addPersonToBooking = () => {
    setShowPopup(true);
    setChangeMade(true)
  };

  const updateBookingInfo = (event) => {
    // const button = event.target;
    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: bookingData.uuid,
        address1: bookingData.address1,
        address2: bookingData.address2,
        city: bookingData.city,
        state: bookingData.state,
        zipcode: bookingData.zipcode,
        phone: bookingData.phone
      })
    })
      // .then(response => response.json())
      .then(async response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setChangeMade(false)
        // button.classList.add('green');
        // setTimeout(() => button.classList.remove('green'), 3000);
        // const sortedData = data.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
        // setWorkSchedules(sortedData);
        // likely needs to update state such the list of services
      })
  }

  const updateMedicalChartSent = (checked) => {
    const newExtraStuff = { ...bookingData.extraStuff, medicalChartSent: checked };
    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: bookingData.uuid,
        extraStuff: newExtraStuff
      })
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        handleFieldChange('extraStuff.medicalChartSent', checked);
        setChangeMade(false);
        setMedicalChartSent(checked);
      })
      .catch(error => {
        console.error('Error updating medical chart sent status:', error);
      });
  };


  const updateNurseArrivalTime = (event) => {
    // const button = event.target;
    const newExtraStuff = { ...bookingData.extraStuff, nurseArrivalTime: new Date().toISOString() }
    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: bookingData.uuid,
        extraStuff: newExtraStuff
      })
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('data from nurse Arrival time:', data);
        if (Array.isArray(data) && data[0] > 0) {
          handleFieldChange('extraStuff.nurseArrivalTime', new Date().toISOString());
          setNurseArrived(true);
          setChangeMade(false);
        } else {
          throw new Error(data.message);
        }
        // button.classList.add('green');
        // setTimeout(() => button.classList.remove('green'), 3000);
        // const sortedData = data.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
        // setWorkSchedules(sortedData);
        // likely needs to update state such the list of services
      }).catch(error => {
        // set a window alert if there is an error
        console.error('Error updating nurse arrival time:', error);
        // set a window alert
        window.alert('Error updating nurse arrival time: ' + error.message);
      });
  }


  const handleTimeChange = (event) => {
    setChangeMade(true)
    setSelectedTime(event.target.value);
  };

  const createFollowUpBooking = (event) => {
    const button = event.target;

    if (selectedTime) {
      fetch('/api/admin/createFollowUpAppointment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          booking: bookingData,
          loggedInUserId: user.uuid,
          startTime: moment(selectedTime).toISOString(),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          button.classList.add('green');
          setTimeout(() => button.classList.remove('green'), 3000);
          // Optionally, you can display a success message here
        })
        .catch((error) => {
          console.error('Error creating follow-up appointment', error);
          // Handle any errors that occur during the API request
        });
    } else {
      setAlertMessage('Please select a time for the follow-up appointment.');
    }
  };

  const handleDateChange = (newDay, newMonth, newYear) => {
    const updatedDay = newDay || day;
    const updatedMonth = newMonth || month;
    const updatedYear = newYear || year;

    if (newDay) setDay(newDay);
    if (newMonth) setMonth(newMonth);
    if (newYear) setYear(newYear);
    const formattedDate = moment({ year: updatedYear, month: updatedMonth - 1, day: updatedDay }).format('YYYY-MM-DD');
    handleFieldChange('patient.birthday', formattedDate);
  };
  return (
    <div className='adminParent adminTabSection'>

      <button style={{ marginLeft: '0px' }}> <GoogleMapsLink bookingData={bookingData}/></button> <br/>
      <button style={{ marginLeft: '0px' }}> <AppleMapsLink bookingData={bookingData}/></button> <br/>
      {user.acl > 3 && <button style={{ marginLeft: '0px' }}>
      <a href={`https://justcall.io/dialer/${bookingData.patient.phone && bookingData.patient.phone.replace(/[^\d]/g, '')}`} target="_blank" rel="noopener noreferrer">
  Call customer
</a>
      </button>}
      <button onClick={() => openForm('HTXWaiver')}>Legal Waiver</button>
      {bookingData.cityCalendarId == 4 && <button onClick={() => openForm('RGVMembership')}>RGV Membership Waiver</button>}
      <br/>
      <h3>Nurse Arrival Confirmed:
        <input
          type="checkbox"
          checked={nurseArrived}
          onChange={(e) => {
            if (e.target.checked) {
              // setNurseArrived(true);
              updateNurseArrivalTime()
            }
          }}
          disabled={nurseArrived}
        />
      {nurseArrived && <> {moment(bookingData.extraStuff?.nurseArrivalTime).format('MM-DD HH:mm A')}</>}
      </h3>
      <h3>Medical Chart Sent to Doctor:
        <input
          type="checkbox"
          checked={medicalChartSent}
          disabled={medicalChartSent}
          onChange={(e) => {
            updateMedicalChartSent(e.target.checked);
          }}
        />
      </h3>
      <h3>Medical Waiver Signed:
        <input
          type="checkbox"
          checked={medicalWaiverSigned}
          disabled={true}
        />
      </h3>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={{ content: { width: '100%', height: '100%' } }}>
        <TreeRenderer treeName={currentTree} closeModal={closeModal} bookingData={bookingData}/>
        <button onClick={closeModal}>Close Form</button>
      </Modal>

      <div className='adminInputSection adminTextField'>
      <h3>Patient Info
        <button onClick={ (event) => { updateBookingInfo(); updatePatientInfo(event) }} className='saveButton'>Save</button>
      </h3>
        <table>
          <tbody>
            <tr>
              <td>Customer notes <br/>at booking: </td>
              <td><div className='adminTextField'>{bookingData.bookingNotes} <br/></div></td>
            </tr>
            <tr>
              <td><label>first name</label></td>
              <td>
                <input
                  type="text"
                  disabled={user.acl < 4}
                  value={bookingData.patient.firstName || ''}
                  onChange={(e) => handleFieldChange('patient.firstName', e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td><label>middle name</label></td>
              <td><input type="text" value={bookingData.patient.middleName || ''} onChange={(e) => handleFieldChange('patient.middleName', e.target.value)} /></td>
            </tr>
            <tr>
              <td><label>last name</label></td>
              <td><input type="text" disabled={user.acl < 4} value={bookingData.patient.lastName || ''} onChange={(e) => handleFieldChange('patient.lastName', e.target.value)} /></td>
            </tr>
            <tr>
              <td><label>sex:</label></td>
              <td>
                <select value={bookingData.patient.sex || ''} onChange={(e) => handleFieldChange('patient.sex', e.target.value)}>
                  <option value= {null} > --- </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </td>
            </tr>
            <tr>
              <td><label>DOB:</label></td>
              <td>
              <br/>
              <div>Month:{' '}
              <select value={month} onChange={(e) => handleDateChange(day, e.target.value, year)} style={{ marginBottom: '10px' }}>
                {months.map((m) => (
                  <option key={m} value={m}>
                    {m}
                  </option>
                ))}
              </select><br/>Day:{' '}
              <select value={day} onChange={(e) => handleDateChange(e.target.value, month, year)} style={{ marginBottom: '10px' }}>
                {days.map((d) => (
                  <option key={d} value={d}>
                    {d}
                  </option>
                ))}
              </select>
              <br/>Year:{' '}
              <select value={year} onChange={(e) => handleDateChange(day, month, e.target.value)} style={{ marginBottom: '10px' }}>
                {years.map((y) => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))}
              </select>
            </div>
            <input
              disabled
              type="date" // adding seven hours to accomodate time zone, not ideal
              value={bookingData.patient ? moment(bookingData.patient.birthday).isValid() ? moment(bookingData.patient.birthday).add(7, 'hours').format('YYYY-MM-DD') : '' : ''}
              onChange={(e) => {
                // Convert date to 'YYYY-MM-DD' format before updating the state
                const formattedDate = e.target.value ? moment(e.target.value).isValid() ? moment(e.target.value).format('YYYY-MM-DD') : '' : '';
                handleFieldChange('patient.birthday', formattedDate);
              }}
            />
              </td>
            </tr>
            {user.acl > 3 && <tr>
              <td><label>Phone (Number only):</label></td>
              <td><input type="text" placeholder="XXXXXXXXXX" value={bookingData.phone || ''} onChange={(e) => {
                const filteredValue = e.target.value.replace(/[^\d]/g, ''); // This will remove any non-digit character
                handleFieldChange('phone', filteredValue);
                handleFieldChange('patient.phone', filteredValue);
              }} /></td>
            </tr>}
            <tr>
              <td><label>Address 1:</label></td>
              <td><input type="text" value={bookingData.address1 || ''} onChange={(e) => handleFieldChange('address1', e.target.value)} /></td>
            </tr>
            <tr>
              <td><label>Address 2:</label></td>
              <td><input type="text" value={bookingData.address2 || ''} onChange={(e) => handleFieldChange('address2', e.target.value)} /></td>
            </tr>
            <tr>
              <td><label>City:</label></td>
              <td><input type="text" value={bookingData.city || ''} onChange={(e) => handleFieldChange('city', e.target.value)} /></td>
            </tr>
            <tr>
              <td><label>State:</label></td>
              <td><input type="text" value={bookingData.state || ''} onChange={(e) => handleFieldChange('state', e.target.value)} /></td>
            </tr>
            <tr>
              <td><label>zip:</label></td>
              <td><input type="text" value={bookingData.zipcode || ''} onChange={(e) => handleFieldChange('zipcode', e.target.value)} /></td>
            </tr>
            <tr>
              <td>Email: </td>
              <td>{bookingData.patient.email}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <div className='adminTextField'>
        <h3>Text Patient Updated Arrival Time</h3>
        <label>ETA: {' '}</label>
        <input
          type="time"
          value={estimatedArrivalTime}
          onChange={handleEstimatedArrivalTimeChange}
        />
        <button onClick={lateText}>Send Message</button>
      </div>
      <div className='adminTextField'>
        <h3>
          Follow up appointment
        </h3>
        <p>create a follow up appointment for the same service</p>
        <input type="datetime-local" value={selectedTime} onChange={handleTimeChange} />
        <button onClick={createFollowUpBooking}>Create Follow-Up</button>
        {alertMessage && <p style={{ color: 'red' }}>{alertMessage}</p>}
      </div>
      <br />
      {!showPopup && (<button className='navigate-button' id="submitNewPersonToBookingButton" onClick={(e) => addPersonToBooking(e)}>Add Person to Booking</button>)}
    {showPopup && (
        <div className="popup">
          <form onSubmit={submitPeopleToBooking}>
            {patientData.map((person, index) => (
              <div key={index}>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={person.firstName}
                  onChange={(e) => handleInputChange(index, e)}
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={person.lastName}
                  onChange={(e) => handleInputChange(index, e)}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={person.email}
                  onChange={(e) => handleInputChange(index, e)}
                  required
                />
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  value={person.phone}
                  onChange={(e) => { e.target.value = e.target.value.replace(/[^\d]/g, ''); handleInputChange(index, e) }}
                  required
                />
              </div>
            ))}
            <button type="submit">Submit</button>
            <button onClick={() => setShowPopup(false)}>Cancel</button>
          </form>
        </div>
    )}
    </div>
  )
}

export default GeneralTabContent;
